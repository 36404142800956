<template>
  <div class="tb-container2" ref="tbContainerRef">
    <!-- 左边的 el-select 元素 -->
    <!-- 右边的元素，使用 flex 容器来包裹它们 -->
    <div style="display: flex; justify-content: flex-end; align-items: center">
      <!-- 条件渲染的 svg-icon -->

      <!-- 条件渲染的 el-input -->
      <el-input
        v-model="inputValue"
        class="w-50 m-2"
        placeholder="Search SN"
        style="width: 20%"
        ><i><Search /></i
      ></el-input>
    </div>
    <!-- 表格 -->
    <el-table
      ref="tableRef"
      :data="
        terminalList.filter(
          (data) =>
            !inputValue ||
            data.sn.includes(inputValue) ||
            data.sn.toLowerCase().includes(inputValue.toLowerCase())
        )
      "
      style="width: 100%; margin-top: 5px"
      @selection-change="handleSelectionChange"
      max-height="800"
      border
      stripe
    >
      <el-table-column
        type="selection"
        width="55"
        class-name="isCheck"
        :selectable="checkboxIsDisable"
      />
      <el-table-column
        v-if="terminalList.length > 0"
        type="index"
        label="Seq"
        :width="50"
      />
      <!-- <el-table-column prop="id" label="ID" width="100" /> -->
      <el-table-column prop="terminalId" label="Terminal ID" />
      <el-table-column prop="sn" label="SN" width="160"> </el-table-column>
      <el-table-column prop="tenantName" label="Tenant"> </el-table-column>
      <el-table-column prop="merchantName" label="Merchant Name">
      </el-table-column>
      <el-table-column prop="batchType" label="Batch Type">
        <template #default="{ row }">
          {{ getCommonParamsItemLabel(row.batchType, 'batch_type') }}
        </template>
      </el-table-column>
      <el-table-column prop="modelId" label="Model Type">
        <template #default="{ row }">
          {{ getGlobalParamsListItemLabel(row.modelId, 'terminalModel') }}
        </template>
      </el-table-column>

      <el-table-column prop="status" label="Status">
        <template #default="{ row }">
          {{ getCommonParamsItemLabel(row.status, 'terminal_status') }}
        </template>
      </el-table-column>
      <el-table-column prop="createdTime" label="Creation Time">
        <template #default="{ row }">
          {{ $filters.getCurrentSystemTimeFormat(row.createdTime) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineExpose, nextTick, watch } from 'vue'
import { useStore } from 'vuex'
import { Search } from '@element-plus/icons'
import {
  getCommonParamsItemLabel,
  getGlobalParamsListItemLabel
} from '@/utils/common'
import { useRoute } from 'vue-router'

const routes = useRoute()
const props = defineProps({
  batchType: {
    type: String,
    default: ''
  },
  tenantId: {
    type: String,
    default: ''
  },
  initialCheckedTerminalList: {
    type: Array,
    default: () => []
  },
  pageType: {
    type: String,
    validator: (value) => ['view', 'edit', 'add'].includes(value)
  },
  terminalList: {
    type: Array,
    default: () => []
  }
})

const tableRef = ref(null)
const store = useStore()
const terminalList = ref([])
const selectedRowItems = ref([])

const formRef = ref(null)
const listData = ref([])

const checkboxIsDisable = (row) => {
  return props.pageType !== 'view'
}

const getValidateRes = () => {
  formRef.value.validate((valid) => {
    return valid
  })
}

const handleSelectionChange = (val) => {
  selectedRowItems.value = val
  // 重置选中状态

  listData.value = listData.value.map((item) => {
    for (let i = 0; i < val.length; i++) {
      // 重置选中状态
      if (item?.checked) item.checked = false
      if (String(item.id) === String(val[i].id)) {
        return {
          ...item,
          checked: true
        }
      }
    }
    return item
  })
}

watch(
  () => selectedRowItems.value.length,
  (newVal, oldVal) => {
    if (newVal === 0 && oldVal > 0) {
      listData.value.forEach((item) => {
        if (item.checked) {
          item.checked = false
        }
      })
    }
  }
)

const inputValue = ref('')
watch(
  () => inputValue.value,
  async (newVal, oldVal) => {
    if (newVal === '' && oldVal !== '') {
      // 保存当前选中的行
      const checkedItems = selectedRowItems.value?.map((item) => item.id)

      nextTick(() => {
        terminalList.value.forEach((item) => {
          if (checkedItems.includes(item.id)) {
            tableRef.value.toggleRowSelection(item, true)
          } else {
            tableRef.value.toggleRowSelection(item, false)
          }
        })
      })
    }
  }
)
const clearSelection = () => {
  tableRef.value.clearSelection()
}

watch(
  () => props.initialCheckedTerminalList,
  (newVal) => {
    if (newVal) {
      loadCheckedItems(props.initialCheckedTerminalList)
    }
  }
)

const getCurrentTerminalListByBatchTenant = async (batchType, tenantId) => {
  const data = await store.dispatch('param/getPageForGroupList', {
    batchType: batchType,
    tenantId: tenantId,
    groupType: 'terminal_param',
    groupId: routes.params.id
  })
  const list = data.map((item) => {
    return item.id
  })
  loadCheckedItems(list)
}

const loadCheckedItems = (checkedList) => {
  if (
    Array.isArray(checkedList) &&
    checkedList.length > 0 &&
    terminalList.value.length > 0
  ) {
    terminalList.value.forEach((item) => {
      if (checkedList.includes(item.id)) {
        tableRef.value.toggleRowSelection(item, true)
      } else {
        tableRef.value.toggleRowSelection(item, false)
      }
    })
  }
}

onMounted(async () => {
  await store.dispatch('globalParams/getTerminalModelList')
  if (props.pageType !== 'add') {
    loadCheckedItems(props.initialCheckedTerminalList)
  }
})

watch(
  () => [props.batchType, props.tenantId],
  (newVal, oldVal) => {
    // 此时返回的是数组,按下标获取对应值
    if (newVal && newVal.length && (newVal[0] || newVal[1])) {
      getCurrentTerminalListByBatchTenant(props.batchType, props.tenantId)
    }
  }
)
const selectedListData = ref([])

watch(
  () => props.terminalList,
  (newVal) => {
    if (newVal) {
      listData.value = JSON.parse(JSON.stringify(newVal))
      terminalList.value = newVal
    }
  },
  { deep: true }
)

watch(
  () => listData.value,
  (newVal) => {
    if (newVal) {
      selectedListData.value = newVal.filter((item) => {
        if (item.checked) {
          return item.key
        }
      })
    }
  },
  { deep: true }
)

const handleResetInputValue = () => {
  inputValue.value = ''
}

defineExpose({
  selectedRowItems,
  getValidateRes,
  selectedListData,
  clearSelection,
  loadCheckedItems,
  handleResetInputValue
})
</script>

<style>
.tb-container2 .el-table .el-table__cell {
  padding: 0 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}
</style>
