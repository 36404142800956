<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfigRef"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      pageType="edit"
      :isResetFields="false"
      @handleNewClick="handleSubmit"
      @resetBtnClick="handleResetClick"
    >
      <template #customForm>
        <el-col :span="24" style="padding: 18px">
          <el-form>
            <el-form-item label="Terminal" required>
              <el-select
                style="width: 100%"
                v-model="selectedTerminals"
                placeholder=" "
                disabled
                multiple
              >
                <el-option
                  v-for="item in terminalOption"
                  :key="item.id"
                  :label="item.sn"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-form>
          <LTable
            ref="terminalTableRef"
            :initialCheckedTerminalList="initialCheckedTerminalList"
            :pageType="'edit'"
            :batchType="batchType"
            :tenantId="tenantId"
            :terminal-list="terminalOption"
          ></LTable>
        </el-col>
      </template>
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseConfigFormConfig } from './config/config.terminal.js'

import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import LTable from './dynamic-table.vue'
import { ref, getCurrentInstance, onMounted, watch } from 'vue'
import i18n from '@/i18n'
import { ElMessage } from 'element-plus'

// 页面标题
const pageTitle = ref('')
const batchType = ref('')
const tenantId = ref('')
const { t } = i18n.global

pageTitle.value = t('general.router-config-terminal-template')

const routes = useRoute()
const router = useRouter()

const id = routes.params.id
const store = useStore()
const pageInfo = ref({})
const { appContext } = getCurrentInstance()
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
const baseFormConfigRef = ref(baseConfigFormConfig)

onMounted(async () => {
  getCurrentPageInfo()
})

const terminalTableRef = ref(null)
const selectedTerminals = ref([])
const initialCheckedTerminalList = ref([])

watch(
  () => terminalTableRef?.value?.selectedRowItems,
  (newVal) => {
    if (newVal) {
      selectedTerminals.value = newVal?.map((item) => item.id)
    }
  }
)

const terminalOption = ref([])

const initialListData = async () => {
  terminalOption.value = await store.dispatch('param/getPageForGroupList', {
    tenantId: ''
  })
  console.log(terminalOption.value)
}

initialListData()

// 获取当前页面信息
const getCurrentPageInfo = async () => {
  const templateInfo = await store.dispatch('param/getTemplateById', {
    id
  })
  templateInfo.tenantId = store.getters.tenantDataMap[templateInfo?.tenantId]
  pageInfo.value = templateInfo
}

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  if (!selectedTerminals.value.length) {
    return ElMessage.warning('Please choose at least one terminal in the list.')
  }
  const form = {
    templateId: id,
    terminalIdList: selectedTerminals.value
  }
  handleMessageCommit(`param/importParamToTerminalFromTemplate`, form).then(
    () => {
      router.push('/template/overview')
    }
  )
}

// 重置
const handleResetClick = () => {
  // 清空所选项
  selectedTerminals.value = []
  terminalTableRef.value.clearSelection()
  // 重置清空SN的查询
  terminalTableRef.value.handleResetInputValue()
}
</script>
